import { put, call } from "redux-saga/effects";
import { actions } from "../slice";
import { actions as resultsActions } from "../../results/slice";
import { actions as searchActions } from "../../search/slice";
import {
    postSearch,
    postResetSearchCount,
    postSetSearchCount,
    getCheckSearchCount,
    deleteSavedSearches,
    getMlSummary,
} from "./helpers";
import { getCookie } from "../../auth/saga/helpers";

function* submitSearch(
    description,
    searchSectors,
    location,
    scope,
    searchType,
    lookAlikeName,
    perPage,
    resultsLimit,
    scope_guide_step_1,
    scope_guide_step_2,
    scope_guide_step_3,
    scope_guide_step_4,
    scope_guide_step_5,
) {
    try {
        yield call(getCookie);
        yield put(actions.postSearchPending());
        yield put(resultsActions.clearResults());
        yield put(searchActions.setGetSummaryUndefined());

        const response = yield call(
            postSearch,
            description,
            searchSectors ? searchSectors : null,
            location ? location : null,
            scope,
            searchType,
            lookAlikeName,
            perPage,
            resultsLimit,
            scope_guide_step_1,
            scope_guide_step_2,
            scope_guide_step_3,
            scope_guide_step_4,
            scope_guide_step_5,
        );

        try {
            yield put(
                resultsActions.setResults({ data: response.data, page: 1 }),
            );
            yield put(actions.postSearchSuccess());
            yield put(
                resultsActions.setCurrentSearchSid(Number(response.data.sid)),
            );
            yield put(resultsActions.setCurrentSearchUnsaved());
        } catch (err) {
            console.log(err);
            yield put(actions.postSearchFailure());
        }
    } catch (err) {
        console.log(err);
        console.log(err.response);
        console.log(err.message);
        console.log({err});
        if (err.response) {
            if (err.response.status === 404) {
                yield put(actions.postMLSearchCompanyNotFound());
            } else if (err.response.status === 442 || err.response.status === 422) {
                yield put(actions.postMLSearchFailure());
            } else if (err.response.status >= 500) {
                yield put(actions.postMLSearchTimeout());
            } else {
                yield put(actions.postSearchFailure());
            }
        } else {
            yield put(actions.postSearchFailure());
        }
    }
}
function* fetchMlSummary(data) {
    try {
        yield put(actions.setGetSummaryPending());
        const response = yield call(getMlSummary, data);
        yield put(actions.setSummaryState(response));
        yield put(actions.setGetSummarySuccess());
    } catch (err) {
        if (process.env.REACT_APP_ENV !== "prod") {
            console.log(err);
        }
        yield put(actions.setGetSummaryFailure());
    }
}
function* checkSearchCount() {
    try {
        yield put(actions.checkSearchCountPending());
        const response = yield call(getCheckSearchCount);
        yield put(actions.setSearchCountState(response));
        yield put(actions.checkSearchCountSuccess());
    } catch (err) {
        yield put(actions.checkSearchCountFailure());
    }
}
function* setSearchCount() {
    try {
        yield put(actions.setSearchCountPending());
        yield call(postSetSearchCount);
        yield put(actions.setSearchCountSuccess());
        yield put(actions.setSearchCountUndefined());
    } catch (err) {
        yield put(actions.setSearchCountFailure());
    }
}
function* resetSearchCount() {
    try {
        yield put(actions.resetSearchCountPending());
        yield call(postResetSearchCount);
        yield put(actions.resetSearchCountSuccess());
    } catch (err) {
        yield put(actions.resetSearchCountFailure());
    }
}

function* removeSavedSearches(data) {
    try {
        yield put(actions.removeSavedSearchesPending());
        yield call(deleteSavedSearches, data);
        yield put(resultsActions.removeSearchesResult(data));
        yield put(actions.removeSavedSearchesSuccess());
        yield put(actions.removeSavedSearchesUndefined());
    } catch (err) {
        yield put(actions.removeSavedSearchesFailure());
    }
}

export {
    submitSearch,
    checkSearchCount,
    resetSearchCount,
    setSearchCount,
    removeSavedSearches,
    fetchMlSummary,
};
